<template>
  <div>
    <el-dialog
      title="在线账户开户-平安银行"
      :visible.sync="dialogFormVisible"
      :before-close="close"
    >
      <el-form label-position="left" ref="childForm" :model="childForm">
        <el-form-item
          size="small"
          label="公司名称"
          :label-width="formLabelWidth"
          prop="appName"
        >
          <el-input
            v-model="childForm.appName"
            :disabled="true"
            placeholder="请输入公司名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          size="small"
          label="APPID"
          :label-width="formLabelWidth"
          prop="appId"
        >
          <el-input
            v-model="childForm.appId"
            placeholder="请输入APPID"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          size="small"
          label="应用私钥"
          :label-width="formLabelWidth"
          prop="appPrivateKey"
        >
          <el-input
            v-model="childForm.appPrivateKey"
            placeholder="请输入应用私钥"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="应用密码"
          :label-width="formLabelWidth"
          prop="appSecret"
        >
          <el-input
            v-model="childForm.appSecret"
            placeholder="请输入应用密码"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->

        <el-form-item
          size="small"
          label="平台平安银行结算账号"
          :label-width="formLabelWidth"
          prop="mainAccount"
        >
          <el-input
            v-model="childForm.mainAccount"
            :disabled="!allowInput"
            placeholder="请输入平台平安银行结算账号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="企业银企直联标准代码"
          :label-width="formLabelWidth"
          prop="accessMode"
        >
          <el-select
            style="width:100%;"
            size="small"
            v-model="childForm.accessMode"
            placeholder="请选择"
          >
            <el-option label="平安银行老模式（独立客户使用）" :value="0"></el-option>
            <el-option label="平安银行新模式（快驰受托客户使用）" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="submit"
          :disabled="isDisable"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { spabankChannel,spabankEditChannel } from '@/request/api'
export default {
  props: {
    dialogFormVisible: { type: Boolean, default: false },
    form: {
      type: Object,
      default: () => ({
        appName: '',
        appPrivateKey: '',
        appSecret: '',
        mainAccount: '',
        appId: '',
        merchCode: '',
        accessMode:0,
        id: '',
      }),
    },
    allowInput: { type: Boolean, default: false },
  },
  data() {
    return {
      isDisable: true,
      formLabelWidth: '160px',
      childForm: this.form,
      fullscreenLoading: false,
    }
  },
  watch: {
    form() {
      this.childForm = { ...this.form }
    },
    childForm: {
      handler(val) {
        let testForm = val
        this.isDisable = testForm.mainAccount ? false : true
      },
      deep: true,
    },
  },
  methods: {
    async channelSubmit(formData) {
      try {
        this.fullscreenLoading = true
        const res = !this.allowInput ? await spabankEditChannel({
          isvId:formData.isvId,
          isvName:formData.appName,
          institutionCode:'SPABANK',
          channelConfig:{
            accessMode:formData.accessMode
          }
        }) : await spabankChannel(formData)
        this.fullscreenLoading = false
        if (res.status === 200) {
          this.$refs.childForm.resetFields()
          this.$message.success('配置成功')
          this.$emit('update-table')
          this.$emit('update:dialogFormVisible', false)
        } else {
          this.$message.error(res.message)
          this.$refs.childForm.resetFields()
        }
      } catch (error) {
        this.fullscreenLoading = false
        this.$emit('update:dialogFormVisible', false)
      }
    },
    submit() {
      this.channelSubmit(this.childForm)
    },
    close() {
      this.$refs.childForm.resetFields()
      this.$emit('update:dialogFormVisible', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.upload_box {
    display: flex;
}
</style>
