<template>
  <el-dialog
    title="平台配置"
    :visible.sync="platformDialogVisible"
    :before-close="close"
    width="520px"
  >
    <p style="font-size:16px;font-weight: 500;padding:0 0 16px 0;">
      充值到账模式
    </p>
    <div class="box">
      <p style="line-height: 24px;">
        默认为实时到账，选择d+1到账则是银行到账后次日凌晨00:00上账
      </p>
      <div class="item">
        <span style="min-width: 100px;">平台主账户:</span>
        <el-select
          style="width:100%;"
          size="small"
          v-model="platformDepositMode"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span style="min-width: 100px;">托运方钱包:</span>
        <el-select
          style="width:100%;"
          size="small"
          v-model="shipperDepositMode"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" size="medium" @click="submit" :loading="loading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { isvSettingQueryApi, isvSettingApi } from '@/request/api'
export default {
  props: {
    platformDialogVisible: { type: Boolean, default: false },
    isvId: { type: String, default: '' },
  },
  data() {
    return {
      loading: false,
      channelList: [
        { label: '实时到账', value: 0 },
        { label: 'd+1到账', value: 1 },
      ],
      platformDepositMode: 0,
      shipperDepositMode: 0,
    }
  },
  watch: {
    platformDialogVisible(v) {
      if (v) {
        this.queryList()
      }
    },
  },
  methods: {
    async queryList() {
      try {
        const p = { isvId: this.isvId }
        const res = await isvSettingQueryApi(p)
        if (res.status === 200 && res.data) {
          this.platformDepositMode = res.data.platformDepositMode
          this.shipperDepositMode = res.data.shipperDepositMode
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    channelOnOff(item, index) {
      this.$set(this.list, index, item)
    },
    close() {
      this.$emit('update:platformDialogVisible', false)
    },
    async submit() {
      this.loading = true
      const p = {
        isvId: this.isvId,
        platformDepositMode: this.platformDepositMode,
        shipperDepositMode: this.shipperDepositMode,
        userTradeNo: new Date().getTime(),
      }
      const res = await isvSettingApi(p)
      this.loading = false
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '操作成功!',
        })
        this.close()
      } else {
        this.$message.error(res.message)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.box {
  border: 1px solid rgb(59, 163, 235);
  border-radius: 6px;
  padding:16px 24px;
  .item {
    display: flex;
    align-items: center;
    margin-top:16px;
  }
}
</style>
