<template>
    <el-dialog title="停用/启用平台支付" :visible.sync="platDialogVisible" :before-close="close" width="500px">
        <div class="content-item" :class="plat.state === 1 ? 'onStyle' : 'offStyle'">
            <span class="channel-name">{{ plat.isvName }}</span>
            <div class="switch-content">
                <el-switch
                    v-model="plat.onOff"
                    @change="platOnOff($event)">
                </el-switch>
                <span class="switch-state" :class="plat.state === 1 ? 'on-text_color' : 'off-text_color'">{{plat.state === 1 ? '已启用' : '已停用'}}</span>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { platChangeOnOff } from '@/request/api'
export default {
    props: {
        platDialogVisible: { type: Boolean, default: false },
        plat: { type: Object, default: null }
    },
    watch: {
        
    },
    methods: {
        platOnOff(value) {
            const confirmButtonText = this.plat.state === 0 ? '确认启用' : '确认停用'
            const confirmText = this.plat.state == 0 ? '启用后，平台及其接入企业和个人用户将可以使用支付系统，请确认后谨慎操作！' : '停用后，平台及其接入企业和个人用户将无法继续使用支付系统，请确认后谨慎操作！'
            this.$confirm(confirmText, '停用/启用平台支付', {
                confirmButtonText: confirmButtonText,
                cancelButtonText: '取消',
                center: true
            }).then(async () => {
                const p = {id: this.plat.id, state: value === true ? '1' : '0'}
                const res = await platChangeOnOff(p)
                if (res.status === 200) {
                    this.$message({
                    type: 'success',
                    message: '提交成功!'
                    });
                    this.$emit('update:platDialogVisible', false)
                    this.$emit('update-tabel')
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        close() {
            this.$emit('update:platDialogVisible', false)
        }
    },
}
</script>

<style lang="stylus" scoped>

.content {
    display: flex;
    flex-direction: column;
}
.content-item {
    display: flex;
    justify-content: space-between;
    height: 120px;
    margin: 10px 10px 0 10px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px; 
    border-radius: 4px;
    background-color: rgb(244, 244, 244, 0.2);
}

.switch-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.switch-state {
    margin-top: 10px;
}
.onStyle {
    background-color: #fff;
    border: 1px solid rgb(59, 163, 235);
}
.offStyle {
    background-color: rgb(244, 244, 244, 0.2);
    border: none;
}
.on-text_color {
    color: rgb(59, 163, 235);
}
.off-text_color {
    color: #666;
}
</style>

