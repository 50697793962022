<template>
  <el-dialog
    title="渠道参数配置"
    :visible.sync="channelSetVisible"
    :before-close="close"
    width="500px"
  >
    <div
      class="content-item onStyle"
      v-for="item in channelList"
      :key="item.institutionCode"
    >
      <div class="channel-name">{{ item.institutionName }}</div>
      <div class="switch-content">
        <span
          class="switch-state"
          @click="gotoSet(item)"
          :class="!item.configured ? 'on-text_color' : 'off-text_color'"
          >{{ !item.configured ? '去配置' : '查看配置' }}</span
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { queryChannelConfigApi } from '@/request/api'
export default {
  props: {
    channelSetVisible: { type: Boolean, default: false },
    isvId: { type: String, default: '' },
    keyDate: { type: Number, default: null },
  },
  data() {
    return {
      channelList: '',
    }
  },
  watch: {
    keyDate() {
      this.list = []
      this.queryList()
    },
  },
  methods: {
    async queryList() {
      try {
        const p = { isvId: this.isvId }
        const res = await queryChannelConfigApi(p)
        if (res.status === 200 && res.data) {
          this.channelList = res.data.data || []
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    gotoSet(item) {
      // if (item.configured) return
      this.$emit('show-dialog', this.isvId, item.institutionCode)
    },
    close() {
      this.$emit('update:channelSetVisible', false)
    },
  },
}
</script>

<style lang="stylus" scoped>

.content {
    display: flex;
    flex-direction: column;
}
.content-item {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
    padding:16px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: rgb(244, 244, 244, 0.2);
}
.switch-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.switch-state {
    cursor:pointer;
}
.onStyle {
    background-color: #fff;
    border: 1px solid rgb(59, 163, 235);
}
.offStyle {
    background-color: rgb(244, 244, 244, 0.2);
    border: none;
}
.on-text_color {
    color: rgb(59, 163, 235);
}
.off-text_color {
    color: #666;
}
</style>
