<template>
  <el-dialog
    title="渠道开关"
    :visible.sync="dialogVisible"
    :before-close="close"
    width="500px"
  >
    <el-row :gutter="20">
      <el-col :span="6">
        <span style="line-height: 32px;">当前主渠道渠道</span>
      </el-col>
      <el-col :span="18">
        <el-select
          style="width:100%;"
          size="small"
          v-model="currentMainChannel"
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelList"
            :key="item.channel"
            :label="item.channelName"
            :value="item.channel"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <div
      class="content-item"
      v-for="(item, index) in list"
      :key="index"
      :class="item.state === 1 ? 'onStyle' : 'offStyle'"
    >
      <span class="channel-name">{{ item.institutionName }}</span>
      <div class="switch-content">
        <el-switch
          v-model="item.onOff"
          @change="channelOnOff(item, index)"
        ></el-switch>
        <span
          class="switch-state"
          :class="item.state === 1 ? 'on-text_color' : 'off-text_color'"
          >{{ item.onOff === true ? 'ON' : 'OFF' }}</span
        >
      </div>
    </div>
    <div v-show="!list.length" class="no-text">暂无更多渠道信息</div>
    <div v-show="list.length" slot="footer" class="dialog-footer">
      <el-button type="primary" size="medium" @click="submit" :loading="loading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { channelListApi, channelSwitchApi } from '@/request/api'
export default {
  props: {
    dialogVisible: { type: Boolean, default: false },
    isvId: { type: String, default: '' },
  },
  data() {
    return {
      list: [],
      onOff: false,
      loading: false,
      channelList: [],
      currentMainChannel: '',
    }
  },
  watch: {
    dialogVisible(v) {
      if (v) {
        this.list = []
        this.queryList()
      }
    },
  },
  methods: {
    async queryList() {
      try {
        this.channelList = []
        const p = { isvId: this.isvId }
        const res = await channelListApi(p)
        if (res.status === 200 && res.data) {
          this.list = res.data.data || []
          this.list.forEach((item) => {
            item.onOff = item.state === 1
            if (item.mainChannel) {
              this.currentMainChannel = item.institutionCode
            }
            this.channelList.push({
              channel: item.institutionCode,
              channelName: item.institutionName,
            })
          })
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    channelOnOff(item, index) {
      this.$set(this.list, index, item)
    },
    close() {
      this.$emit('update:dialogVisible', false)
    },
    async submit() {
      if (!this.currentMainChannel) {
        this.$message.error('请选择主渠道')
        return
      }
      let pList = []
      this.list.forEach((item) => {
        let d = {
          institutionCode: item.institutionCode,
          state: item.onOff ? 1 : 0,
          mainChannel: item.institutionCode === this.currentMainChannel,
        }
        pList.push(d)
      })
      this.loading = true
      const p = {
        isvId: this.isvId,
        channelSwitches: pList,
      }
      const res = await channelSwitchApi(p)
      this.loading = false
      if (res.status === 200) {
        this.$message({
          type: 'success',
          message: '操作成功!',
        })
        this.close()
      } else {
        this.$message.error(res.message)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>

.content {
    display: flex;
    flex-direction: column;
}
.content-item {
    display: flex;
    justify-content: space-between;
    height: 80px;
    margin: 10px 0;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    border-radius: 4px;
    background-color: rgb(244, 244, 244, 0.2);
}

.switch-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.switch-state {
    margin-top: 10px;
}
.no-text {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}
.onStyle {
    background-color: #fff;
    border: 1px solid rgb(59, 163, 235);
}
.offStyle {
    background-color: rgb(244, 244, 244, 0.2);
    border: none;
}
.on-text_color {
    color: rgb(59, 163, 235);
}
.off-text_color {
    color:#666;
}
</style>
