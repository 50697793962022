<template>
  <div>
    <el-dialog
      title="在线账户开户-工商银行（聚富通2.0）"
      :visible.sync="dialogFormVisible"
      :before-close="close"
    >
      <el-form label-position="left" ref="childForm" :model="childForm">
        <el-form-item
          size="small"
          label="公司名称"
          :label-width="formLabelWidth"
          prop="appName"
        >
          <el-input
            v-model="childForm.appName"
            :disabled="true"
            placeholder="请输入公司名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="平台付款账号"
          :label-width="formLabelWidth"
          prop="accountNo"
        >
          <el-input
            v-model="childForm.accountNo"
            placeholder="请输入平台付款账号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="平台付款账号名称"
          :label-width="formLabelWidth"
          prop="accountName"
        >
          <el-input
            v-model="childForm.accountName"
            placeholder="请输入平台付款账号名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          size="small"
          label="外部系统编号"
          :label-width="formLabelWidth"
          prop="appIdSub"
        >
          <el-input
            v-model="childForm.appIdSub"
            :disabled="!allowInput"
            placeholder="请输入外部系统编号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="app私钥"
          :label-width="formLabelWidth"
          prop="privateKey"
        >
          <el-input
            v-model="childForm.privateKey"
            :disabled="!allowInput"
            placeholder="请输入app私钥"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="平台appId"
          :label-width="formLabelWidth"
          prop="appId"
        >
          <el-input
            v-model="childForm.appId"
            :disabled="!allowInput"
            placeholder="请输入平台appId"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="平台appAES"
          :label-width="formLabelWidth"
          prop="encryptKey"
        >
          <el-input
            v-model="childForm.encryptKey"
            :disabled="!allowInput"
            placeholder="请输入平台appAES"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="sftp用户名"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="childForm.sftpUserName"
            :disabled="!allowInput"
            placeholder="请输入sftp用户名"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-show="allowInput"
          size="small"
          label="sftp私钥文件"
          :label-width="formLabelWidth"
        >
          <span>请上传后缀是jks的文件</span>
          <input
            id="fileupload2"
            type="file"
            accept=".jks"
            @change="changeUpload($event, 'sftpPrivateKey')"
          />
        </el-form-item>
      </el-form>
      <div v-if="allowInput" slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="submit"
          :disabled="isDisable"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { icbcv3Channel } from '@/request/api'
export default {
  props: {
    dialogFormVisible: { type: Boolean, default: false },
    form: {
      type: Object,
      default: () => ({
        appName: '',
        appId:'',
        accountNo:'',
        accountName:'',
        appIdSub: '',
        encryptKey: '',
        privateKey: '',
        sftpUserName:'',
        id: '',
        signType: 'RSA2',
        encryptType:'AES'
      }),
    },
    allowInput: { type: Boolean, default: false },
  },
  data() {
    return {
      isDisable: true,
      formLabelWidth: '140px',
      childForm: this.form,
      fullscreenLoading: false,
      applicationExcelFile: '',
    }
  },
  watch: {
    form() {
      this.childForm = { ...this.form }
    },
    childForm: {
      handler(val) {
        let testForm = val
        this.isDisable =
          testForm.encryptKey &&
          testForm.privateKey &&
          testForm.appIdSub &&
          testForm.sftpUserName &&
          testForm.appId &&
          testForm.accountNo &&
          testForm.accountName
            ? false
            : true
      },
      deep: true,
    },
  },
  methods: {
    async icbcChannelSubmit(formData) {
      try {
        this.fullscreenLoading = true
        const res = await icbcv3Channel(formData)
        this.fullscreenLoading = false
        if (res.status === 200) {
          this.$refs.childForm.resetFields()
          this.$message.success('配置成功')
          this.$emit('update-table')
          this.$emit('update:dialogFormVisible', false)
        } else {
          this.$message.error(res.message)
          this.$refs.childForm.resetFields()
        }
      } catch (error) {
        this.fullscreenLoading = false
        this.$emit('update:dialogFormVisible', false)
      }
    },
    changeUpload(event, item) {
      const reader = new FileReader()
      if (event.target.files[0]) {
        reader.readAsText(event.target.files[0], 'utf8')
        reader.onload = (event) => {
          this.$nextTick(() => {
            this.childForm[item] = event.target.result
          })
        }
      } else {
        this.childForm[item] = ''
      }
    },
    submit() {
      this.icbcChannelSubmit(this.childForm)
    },
    close() {
      this.$refs.childForm.resetFields()
      var file2 = document.getElementById('fileupload2')
      file2.value = ''
      this.$emit('update:dialogFormVisible', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.upload_box {
    display: flex;
}
</style>
