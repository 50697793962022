import { render, staticRenderFns } from "./SPABSTARChannelDialog.vue?vue&type=template&id=f701243e&scoped=true&"
import script from "./SPABSTARChannelDialog.vue?vue&type=script&lang=js&"
export * from "./SPABSTARChannelDialog.vue?vue&type=script&lang=js&"
import style0 from "./SPABSTARChannelDialog.vue?vue&type=style&index=0&id=f701243e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f701243e",
  null
  
)

export default component.exports