<template>
  <div>
    <el-dialog
      title="在线账户开户-光大银行（物流通）"
      :visible.sync="dialogFormVisible"
      :before-close="close"
    >
      <el-form label-position="left" ref="childForm" :model="childForm">
        <el-form-item
          size="small"
          label="公司名称"
          :label-width="formLabelWidth"
          prop="appName"
        >
          <el-input
            v-model="childForm.appName"
            :disabled="true"
            placeholder="请输入公司名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="商户号"
          :label-width="formLabelWidth"
          prop="busiNum"
        >
          <el-input
            v-model="childForm.busiNum"
            :disabled="!allowInput"
            placeholder="请输入商户号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="自有资金账号"
          :label-width="formLabelWidth"
          prop="equityFundAccNo"
        >
          <el-input
            v-model="childForm.equityFundAccNo"
            :disabled="!allowInput"
            placeholder="请输入自有资金账号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="保证金账号"
          :label-width="formLabelWidth"
          prop="marginAccNo"
        >
          <el-input
            v-model="childForm.marginAccNo"
            :disabled="!allowInput"
            placeholder="请输入保证金账号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div v-if="allowInput" slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="submit"
          :disabled="isDisable"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cebStorageChannel } from '@/request/api'
export default {
  props: {
    dialogFormVisible: { type: Boolean, default: false },
    form: {
      type: Object,
      default: () => ({
        appName: '',
        busiNum: '',
        equityFundAccNo: '',
        id: '',
        marginAccNo: '',
      }),
    },
    allowInput: { type: Boolean, default: false },
  },
  data() {
    return {
      isDisable: true,
      formLabelWidth: '120px',
      childForm: this.form,
      fullscreenLoading: false,
    }
  },
  watch: {
    form() {
      this.childForm = { ...this.form }
    },
    childForm: {
      handler(val) {
        let testForm = val
        this.isDisable =
          testForm.appName &&
          testForm.busiNum &&
          testForm.equityFundAccNo &&
          testForm.marginAccNo
            ? false
            : true
      },
      deep: true,
    },
  },
  methods: {
    async postalStorageChannelSubmit() {
      let p = {
        id: this.childForm.id,
        busiNum: this.childForm.busiNum,
        equityFundAccNo: this.childForm.equityFundAccNo,
        marginAccNo: this.childForm.marginAccNo,
      }
      try {
        this.fullscreenLoading = true
        const res = await cebStorageChannel(p)
        this.fullscreenLoading = false
        if (res.status == 200) {
          this.$refs.childForm.resetFields()
          this.$emit('update-table')
          this.$emit('update:dialogFormVisible', false)
          this.$message.success('配置成功！')
        } else {
          this.$message.error(res.message)
          this.$refs.childForm.resetFields()
        }
      } catch (error) {
        this.fullscreenLoading = false
        this.$refs.childForm.resetFields()
      }
    },
    submit() {
      this.postalStorageChannelSubmit()
    },
    close() {
      this.$refs.childForm.resetFields()
      this.$emit('update:dialogFormVisible', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.upload_box {
    display: flex;
}
</style>
