<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :before-close="close"
    >
      <el-form
        :model="childForm"
        ref="childForm"
        label-position="left"
        :rules="rules"
      >
        <el-form-item
          label="企业名称"
          :label-width="formLabelWidth"
          prop="isvName"
        >
          <el-input
            size="small"
            v-model="childForm.isvName"
            :disabled="!isAddOrEdit"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="营业执照号码"
          :label-width="formLabelWidth"
          prop="idNo"
        >
          <el-input
            size="small"
            v-model="childForm.idNo"
            :disabled="!isAddOrEdit"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系人"
          :label-width="formLabelWidth"
          prop="contact"
        >
          <el-input
            size="small"
            v-model="childForm.contact"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系人手机号"
          :label-width="formLabelWidth"
          prop="contactNumber"
        >
          <el-input
            size="small"
            v-model="childForm.contactNumber"
            maxlength="11"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="回调地址"
          :label-width="formLabelWidth"
          prop="notifyUrl"
        >
          <el-input
            size="small"
            v-model="childForm.notifyUrl"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="入驻产业园"
          :label-width="formLabelWidth"
          prop="industryName"
        >
          <el-select
            class="right"
            size="small"
            v-model="childForm.industryName"
            placeholder="请选择"
            @change="selectIndustryRes"
          >
            <el-option
              v-for="item in industryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <p class="r-span" v-if="!isAddOrEdit" @click="download" v-perm="exportPer">
        {{ childForm.isvName }}ISV密钥下载（加密文件类型）
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          :disabled="isDisable"
          @click="submit"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addPlat, editPlat } from '@/request/api'
export default {
  props: {
    dialogFormVisible: { type: Boolean, default: false },
    isAddOrEdit: { type: Boolean, default: true },
    title: { type: String, default: '' },
    industryList: { type: Array, default: () => [] },
    form: {
      type: Object,
      default: () => ({
        isvName: '',
        contact: '',
        contactNumber: '',
        industryName: '',
        idNo: '',
        industryId: '',
        id: '',
        notifyUrl: '',
      }),
    },
  },
  data() {
    var checkIsvName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('企业名称不能为空'))
      }
    }
    var checkIdNo = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('营业执照号码不能为空'))
      }
    }
    var checkContact = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('联系人不能为空'))
      }
    }
    var checkContactNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('联系人手机号不能为空'))
      } else {
        if (value.length !== 11) {
          return callback(new Error('请输入11位手机号码'))
        }
      }
    }
    var checkIndustryName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择产业园'))
      }
    }
    var checkNotifyUrl = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入回调地址'))
      }
    }
    return {
      isDisable: true,
      formLabelWidth: '120px',
      childForm: this.form,
      industryId: '', // 产业园id
      fullscreenLoading: false,
      exportPer: '/isv/edit',
      rules: {
        isvName: [{ validator: checkIsvName, trigger: 'blur' }],
        idNo: [{ validator: checkIdNo, trigger: 'blur' }],
        industryName: [{ validator: checkIndustryName, trigger: 'blur' }],
        contact: [{ validator: checkContact, trigger: 'blur' }],
        contactNumber: [{ validator: checkContactNumber, trigger: 'blur' }],
        notifyUrl: [{ validator: checkNotifyUrl, trigger: 'blur' }],
      },
    }
  },

  watch: {
    form() {
      this.childForm = this.form
      console.log('this.childForm', this.childForm)
    },
    childForm: {
      handler(val) {
        let testForm = val
        this.isDisable =
          testForm.isvName &&
          testForm.contact &&
          testForm.contactNumber &&
          testForm.idNo &&
          testForm.notifyUrl &&
          testForm.industryName
            ? testForm.contactNumber.length === 11
              ? false
              : true
            : true
      },
      deep: true,
    },
  },
  methods: {
    async download() {
      try {
        window.location.href =
          '/api/isv/exportIsvConfig?isvId=' + this.form.isvId
      } catch (error) {
        console.log(error)
      }
    },
    async addPlatRequest() {
      const p = {
        isvName: this.childForm.isvName,
        idNo: this.childForm.idNo,
        industryId: this.childForm.industryId,
        contact: this.childForm.contact,
        contactNumber: this.childForm.contactNumber,
        notifyUrl: this.childForm.notifyUrl,
      }
      try {
        this.fullscreenLoading = true
        const res = await addPlat(p)
        this.fullscreenLoading = false
        if (res.status === 200 && res.data) {
          this.$refs.childForm.resetFields()
          this.$emit('update:dialogFormVisible', false)
          this.$emit('updateTableList')
        } else {
          this.$message.error(res.message)
          this.$refs.childForm.resetFields()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async editPlatRequest() {
      const p = {
        id: this.childForm.id,
        contact: this.childForm.contact,
        contactNumber: this.childForm.contactNumber,
        notifyUrl: this.childForm.notifyUrl,
        industryId: this.childForm.industryId,
      }
      try {
        this.fullscreenLoading = true
        const res = await editPlat(p)
        this.fullscreenLoading = false
        if (res.status === 200 && res.data) {
          this.$refs.childForm.resetFields()
          this.$emit('updateTableList')
          this.$emit('update:dialogFormVisible', false)
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    selectIndustryRes(id) {
      this.childForm.industryId = id
    },
    submit() {
      // 提交
      if (this.isAddOrEdit === true) {
        this.addPlatRequest()
      } else {
        this.editPlatRequest()
      }
    },
    close() {
      this.$refs.childForm.resetFields()
      this.$emit('update:dialogFormVisible', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.el-autocomplete {
    width: 100%;
}
.el-select {
    width: 100%;
}
.r-span{cursor:pointer;color:#3ba3eb;text-align:right;}
</style>
