<template>
  <div class="table">
    <section class="table-style">
      <el-table
        ref="multipleTable"
        :data="tableData"
        :size="size"
        :show-summary="showSummary"
        :summary-method="summaryMethod"
        :empty-text="emptyText"
        stripe
        :border="isBorder"
        @select="select"
        @select-all="selectAll"
        v-loading="loading"
        :height="inTableHeight"
        style="width: 100%"
        :defaultSelections="defaultSelections"
      >
        <el-table-column
          v-if="isSelection"
          align="center"
          type="selection"
          fixed
        >
        </el-table-column>
        <el-table-column
          v-if="isIndex"
          align="center"
          type="index"
          :label="indexLabel"
          width="50"
          fixed
        >
        </el-table-column>
        <af-table-column
          v-for="item in propCol"
          :key="item.id"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :align="item.align"
          :render-header="item.require ? renderHeader : null"
        >
          <template slot-scope="scope">
            <!-- 按钮 -->
            <span v-if="item.type === 'Button'" v-perm="item.permission">
              <el-popover placement="right" trigger="hover">
                <ul>
                  <li
                    v-for="op in item.op"
                    :key="op.class"
                    class="popList"
                    @click.stop="hadnleOperate(scope.$index, scope.row, op)"
                    v-perm="op.permission"
                  >
                    {{ op.text }}
                  </li>
                </ul>
                <i slot="reference" class="el-icon-s-tools"></i>
              </el-popover>
            </span>
            <!-- 点击详情 -->
            <span v-if="item.type === 'Detail'" v-perm="item.permission">
              <p class="detailClick" @click.stop="handleDetail(scope.row)">
                {{ item.special ? item.special : scope.row[item.prop] }}
              </p>
            </span>
            <!-- 下拉框 -->
            <el-select
              v-if="item.type === 'Select'"
              v-model="scope.row[item.prop]"
              :size="size"
              :props="item.props"
              :disabled="btn.isDisabled && btn.isDisabled(scope.row)"
              @change="item.change && item.change(scope.row)"
            >
              <el-option
                v-for="op in item.options"
                :label="op[item.props.label]"
                :value="op[item.props.value]"
                :key="op[item.props.value]"
              ></el-option>
            </el-select>
            <!-- 默认 -->
            <span
              v-if="!item.type"
              :style="item.itemStyle && item.itemStyle(scope.row)"
              :class="item.itemClass && item.itemClass(scope.row)"
              >{{
                (item.formatter && item.formatter(scope.row)) ||
                  scope.row[item.prop]
              }}</span
            >
          </template>
        </af-table-column>
      </el-table>
    </section>
    <!-- 分页 -->
    <section class="ces-pagination" v-if="isPagination">
      <el-pagination
        style="justify-content: start;height: 100%;align-items: center;padding:8px;"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total,sizes ,prev, pager, next,jumper"
        :page-size="pagination.pageSize"
        :current-page="pagination.pageNum"
        :total="pagination.total"
      ></el-pagination>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    height: { type: [Number, String], default: () => null },
    // 表格型号：mini,medium,small
    size: { type: String, default: 'medium' },
    emptyText: { type: String, default: '暂无数据' },
    isBorder: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    showSummary: { type: Boolean, default: false },
    summaryMethod: { type: Function, default: null },
    // 表格数据
    tableData: { type: Array, default: () => [] },
    // 表格列配置
    tableCols: { type: Array, default: () => [] },
    // 是否显示表格复选框
    isSelection: { type: Boolean, default: false },
    defaultSelections: { type: [Array, Object], default: () => null },
    // 是否显示表格索引
    isIndex: { type: Boolean, default: false },
    indexLabel: { type: String, default: '序号' },
    // 是否显示分页
    isPagination: { type: Boolean, default: true },
    // 分页数据
    pagination: {
      type: Object,
      default: () => ({ pageSize: 10, pageNum: 1, total: 0 }),
    },
  },
  data() {
    return {
      proData: this.tableData,
      propCol: this.tableCols,
      inTableHeight: null,
    }
  },
  watch: {
    defaultSelections(val) {
      this.$nextTick(() => {
        if (Array.isArray(val)) {
          val.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        } else {
          this.$refs.multipleTable.toggleRowSelection(val)
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.changeTableHight()
    })
  },
  methods: {
    // 表格勾选
    select(rows, row) {
      this.$emit('select', rows, row)
    },
    // 全选
    selectAll(rows) {
      this.$emit('select', rows)
    },
    // 翻页
    handleCurrentChange(val) {
      this.pagination.pageNum = val
      this.$emit('handleCurrentChange', val)
    },
    // 变更每页条数
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.$emit('handleSizeChange', val)
    },
    // 操作按钮
    hadnleOperate(index, row, operate) {
      this.$emit('hadnleOperate', index, row, operate)
    },
    // 点击详情
    handleDetail(row) {
      this.$emit('handleDetail', row)
    },
    changeTableHight() {
      if (this.height) {
        //如果有传进来高度就取消自适应
        this.inTableHeight = this.height
        this.$refs.multipleTable.doLayout()
        return
      }
      let tableHeight = window.innerHeight || document.body.clientHeight
      //高度设置
      let disTop = this.$refs.multipleTable.$el
      //如果表格上方有元素则减去这些高度适应窗口，44是底下留白部分
      tableHeight -= disTop.offsetTop + 44
      if (disTop.offsetParent) tableHeight -= disTop.offsetParent.offsetTop
      this.inTableHeight = tableHeight < 460 ? 460 : tableHeight
      //重绘表格
      this.$refs.multipleTable.doLayout()
    },
  },
}
</script>

<style lang="stylus" scoped>
.popList {
    cursor: pointer;
    padding:3px 4px;
    border-radius:3px;
    &:hover {
        background: $spColor;
        color: #fff;
    }
}

.detailClick {
    color: $spColor;
    cursor: pointer;
}

.table-style >>> .el-popover__reference-wrapper {
    color: $spColor;
}

.table >>> .el-table__row .cell {
    font-family: 'Helvetica Neue';
}
.ces-pagination{text-align:right;}
</style>
