<template>
  <div>
    <el-dialog
      title="在线账户开户-平安银行财资平台"
      :visible.sync="dialogFormVisible"
      :before-close="close"
    >
      <el-form label-position="right" ref="childForm" :model="childForm">
        <el-form-item
          size="small"
          label="公司名称"
          :label-width="formLabelWidth"
          prop="appName"
        >
          <el-input
            v-model="childForm.appName"
            :disabled="true"
            placeholder="请输入公司名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="平安银行一般结算户"
          :label-width="formLabelWidth"
          prop="mainAccount"
        >
          <el-input
            v-model="childForm.mainAccount"
            :disabled="!allowInput"
            placeholder="请输入平安银行一般结算户"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          size="small"
          label="APPID"
          :label-width="formLabelWidth"
          prop="appId"
        >
          <el-input
            v-model="childForm.appId"
            :disabled="!allowInput"
            placeholder="请输入APPID"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          size="small"
          label="APPSECRET"
          :label-width="formLabelWidth"
          prop="appSecret"
        >
          <el-input
            v-model="childForm.appSecret"
            :disabled="!allowInput"
            placeholder="请输入APPSECRET"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          v-show="allowInput"
          size="small"
          label="公钥"
          :label-width="formLabelWidth"
          prop="publicKey"
        >
          <el-input
            v-model="childForm.publicKey"
            :disabled="!allowInput"
            placeholder="请输入publicKey"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item
          v-show="allowInput"
          size="small"
          label="私钥"
          :label-width="formLabelWidth"
          prop="appPrivateKey"
        >
          <el-input
            v-model="childForm.appPrivateKey"
            :disabled="!allowInput"
            placeholder="请输入私钥"
            autocomplete="off"
          ></el-input>
        </el-form-item> -->
        <el-form-item
          size="small"
          label="关联银行"
          :label-width="formLabelWidth"
          prop="relateInstitutionName"
        >
          <!-- <el-input
            v-if="!allowInput"
            v-model="childForm.relateInstitutionName"
            :disabled="!allowInput"
            autocomplete="off"
          ></el-input> -->
          <el-select
            class="right"
            v-model="childForm.relateInstitutionName"
            size="small"
            filterable
            remote
            reserve-keyword
            placeholder="请输入银行名称"
            @change="changeOption($event)"
            :remote-method="remoteMethod"
            :loading="inputLoading"
          >
            <el-option
              v-for="item in institutionList"
              :key="item.code"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          size="small"
          label="关联银行账号"
          :label-width="formLabelWidth"
          prop="relateAccountNo"
        >
          <el-input
            v-model="childForm.relateAccountNo"
            placeholder="请输入关联银行账号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="submit"
          :disabled="isDisable"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  postSpabStarChannel,
  institutionList,
  postSpabStarUpdateChannel,
} from '@/request/api'
export default {
  props: {
    dialogFormVisible: { type: Boolean, default: false },
    form: {
      type: Object,
      default: () => ({
        appName: '',
        // appPrivateKey: '',
        // appSecret: '',
        id: '',
        mainAccount: '',
        // publicKey: '',
        relateAccountNo: '',
        relateInstitutionCode: '',
        relateInstitutionName: '',
      }),
    },
    allowInput: { type: Boolean, default: false },
  },
  data() {
    return {
      isDisable: true,
      formLabelWidth: '150px',
      childForm: this.form,
      fullscreenLoading: false,
      institutionList: [],
      inputLoading: false,
    }
  },
  watch: {
    form() {
      this.childForm = { ...this.form }
    },
    childForm: {
      handler(val) {
        let testForm = val
        this.isDisable =
          testForm.appName &&
          // testForm.appPrivateKey &&
          // testForm.appSecret &&
          testForm.mainAccount &&
          // testForm.publicKey &&
          testForm.relateAccountNo &&
          testForm.relateInstitutionCode
            ? false
            : true
      },
      deep: true,
    },
  },
  methods: {
    changeOption(v) {
      this.institutionList.forEach((item) => {
        if (item.name === v) {
          this.childForm.relateInstitutionCode = item.code
        }
      })
    },
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          name: query,
        }
        this.inputLoading = true
        const res = await institutionList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.institutionList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleSubmit() {
      try {
        this.fullscreenLoading = true
        const params = {
          isvId: this.childForm.isvId,
          isvName: this.childForm.appName,
          institutionCode: 'SPAB_STAR',
          channelConfig: {
            relateAccountNo: this.childForm.relateAccountNo,
            relateInstitutionCode: this.childForm.relateInstitutionCode,
            relateInstitutionName: this.childForm.relateInstitutionName,
          },
        }
        const res = this.allowInput
          ? await postSpabStarChannel(this.childForm)
          : await postSpabStarUpdateChannel(params)
        this.fullscreenLoading = false
        if (res.status == 200) {
          this.clearForm()
          this.$emit('update-table')
          this.$emit('update:dialogFormVisible', false)
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.fullscreenLoading = false
      }
    },
    clearForm() {
      this.$refs.childForm.resetFields()
    },
    submit() {
      this.handleSubmit()
    },
    close() {
      this.clearForm()
      this.$emit('update:dialogFormVisible', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.upload_box {
    display: flex;
}
</style>
