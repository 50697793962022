<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :before-close="close"
    >
      <span
        v-if="form.institutionCode === 'ANTB_V2' && allowInput"
        class="r-span"
      >
        <el-checkbox v-model="checked"
          >自动获取网商银行2.0公私钥信息</el-checkbox
        >
      </span>
      <el-form
        label-position="left"
        ref="childForm"
        :model="childForm"
        style="clear:both;"
      >
        <el-form-item
          size="small"
          label="公司名称"
          :label-width="formLabelWidth"
          prop="appName"
        >
          <el-input
            v-model="childForm.appName"
            :disabled="true"
            placeholder="请输入公司名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="机构isvid"
          :label-width="formLabelWidth"
          prop="isvOrgId"
        >
          <el-input
            v-model="childForm.isvOrgId"
            :disabled="!allowInput"
            placeholder="请输入机构svid"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="应用id"
          :label-width="formLabelWidth"
          prop="appId"
        >
          <el-input
            v-model="childForm.appId"
            :disabled="!allowInput"
            placeholder="请输入应用id"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="allowInput"
          size="small"
          label="公钥文件"
          :label-width="formLabelWidth"
          prop="publicKey"
        >
          <div class="upload_box">
            <el-input :disabled="true" v-model="childForm.publicKey"></el-input>
            <el-upload
              action=""
              ref="upload"
              :auto-upload="false"
              :show-file-list="false"
              :multiple="false"
              :on-change="publicFileChanage"
            >
              <el-button
                ><i class="el-icon-upload el-icon--left"></i>上传文件</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item
          v-if="allowInput"
          size="small"
          label="私钥文件地址"
          :label-width="formLabelWidth"
          prop="privateKey"
        >
          <div class="upload_box">
            <el-input
              :disabled="true"
              v-model="childForm.privateKey"
            ></el-input>
            <el-upload
              action=""
              ref="upload"
              :auto-upload="false"
              :show-file-list="false"
              :multiple="false"
              :on-change="privateFileChanage"
            >
              <el-button
                ><i class="el-icon-upload el-icon--left"></i>上传文件</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div v-if="allowInput" slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          :disabled="isDisable"
          @click="submit"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  internetChannel,
  internetV2Channel,
  syncAntbV2ConfigFromAntb,
} from '@/request/api'
export default {
  props: {
    dialogFormVisible: { type: Boolean, default: false },
    form: {
      type: Object,
      default: () => ({
        appName: '',
        isvOrgId: '',
        appId: '',
        publicKey: '',
        privateKey: '',
        id: '',
      }),
    },
    allowInput: { type: Boolean, default: false },
  },
  data() {
    return {
      checked: true,
      formLabelWidth: '120px',
      childForm: this.form,
      publicKeyFile: '',
      privateKeyFile: '',
      fullscreenLoading: false,
      isDisable: false,
      title: '',
    }
  },
  watch: {
    form() {
      this.childForm = { ...this.form }
      console.log(this.form.institutionCode)
      this.title =
        this.form.institutionCode === 'ANTB'
          ? '在线账户开户（网商银行）'
          : '在线账户开户（网商银行2.0）'
    },
    childForm: {
      handler(val) {
        let testForm = val
        this.isDisable =
          testForm.isvOrgId && testForm.appId
            ? // testForm.publicKey &&
              // testForm.privateKey
              false
            : true
      },
      deep: true,
    },
  },
  methods: {
    async setConfigFromAntb() {
      const p = {
        isvId: this.form.isvId,
        isvOrgId: this.childForm.isvOrgId,
        appId: this.childForm.appId,
      }
      try {
        const res = await syncAntbV2ConfigFromAntb(p)
        if (res.status === 200) {
          this.$message.success('操作成功')
          this.$emit('update-table')
          this.$emit('update:dialogFormVisible', false)
          this.$emit('update:channelSetVisible', false)
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        console.log(error)
      }
    },
    publicFileChanage(file) {
      this.childForm.publicKey = file.name
      this.publicKeyFile = file.raw
    },
    privateFileChanage(file) {
      this.childForm.privateKey = file.name
      this.privateKeyFile = file.raw
    },
    // 网商渠道信息提交
    async internetChannelSubmit(formData) {
      const p = formData
      let res = {}
      try {
        this.fullscreenLoading = true
        if (this.form.institutionCode.toUpperCase() === 'ANTB') {
          res = await internetChannel(p)
        } else {
          if (this.checked) {
            this.setConfigFromAntb()
            this.fullscreenLoading = false
            return
          } else {
            res = await internetV2Channel(p)
          }
        }
        // const res =
        //   this.form.institutionCode.toUpperCase() === 'ANTB'
        //     ? await internetChannel(p)
        //     : await internetV2Channel(p)
        this.fullscreenLoading = false
        if (res.status === 200) {
          this.$refs.childForm.resetFields()
          this.$emit('update-table')
          this.$emit('update:dialogFormVisible', false)
        } else {
          this.$message.error(res.message)
          this.$refs.childForm.resetFields()
        }
      } catch (error) {
        this.fullscreenLoading = false
      }
    },
    submit() {
      let formData = new FormData()
      formData.append('privateKeyFile', this.privateKeyFile)
      formData.append('publicKeyFile', this.publicKeyFile)
      formData.append('id', this.childForm.id)
      formData.append('isvOrgId', this.childForm.isvOrgId)
      formData.append('appId', this.childForm.appId)
      this.internetChannelSubmit(formData)
    },
    close() {
      this.$refs.childForm.resetFields()
      this.$emit('update:dialogFormVisible', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.upload_box {
    display: flex;
}
.r-span{cursor:pointer;color:#3ba3eb;padding-bottom:16px;text-align:right;float:right;}
</style>
