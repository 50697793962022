<template>
  <div>
    <el-dialog
      title="在线账户开户-工商银行（银企直联）"
      :visible.sync="dialogFormVisible"
      :before-close="close"
    >
      <el-form label-position="left" ref="childForm" :model="childForm">
        <el-form-item
          size="small"
          label="公司名称"
          :label-width="formLabelWidth"
          prop="appName"
        >
          <el-input
            v-model="childForm.appName"
            :disabled="true"
            placeholder="请输入公司名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="银行名称"
          :label-width="formLabelWidth"
          prop="bankOrganization"
        >
          <el-input
            v-model="childForm.bankOrganization"
            :disabled="!allowInput"
            placeholder="请输入银行名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          size="small"
          label="银行卡号"
          :label-width="formLabelWidth"
          prop="ecis"
        >
          <el-input
            v-model="childForm.ecis"
            :disabled="!allowInput"
            placeholder="请输入银行卡号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="allowInput"
          size="small"
          label="公钥文件"
          :label-width="formLabelWidth"
          prop="publicKey"
        >
          <div class="upload_box">
            <el-input :disabled="true" v-model="childForm.publicKey"></el-input>
            <el-upload
              action=""
              ref="upload"
              :auto-upload="false"
              :show-file-list="false"
              :multiple="false"
              :on-change="publicFileChanage"
            >
              <el-button
                ><i class="el-icon-upload el-icon--left"></i>上传文件</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item
          v-if="allowInput"
          size="small"
          label="私钥文件地址"
          :label-width="formLabelWidth"
          prop="privateKeyAddress"
        >
          <div class="upload_box">
            <el-input
              :disabled="true"
              v-model="childForm.privateKeyAddress"
            ></el-input>
            <el-upload
              action=""
              ref="upload"
              :auto-upload="false"
              :show-file-list="false"
              :multiple="false"
              :on-change="privateFileChanage"
            >
              <el-button
                ><i class="el-icon-upload el-icon--left"></i>上传文件</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item
          v-if="allowInput"
          size="small"
          label="申请表地址"
          :label-width="formLabelWidth"
          prop="tableAddress"
        >
          <div class="upload_box">
            <el-input
              :disabled="true"
              v-model="childForm.tableAddress"
            ></el-input>
            <el-upload
              action=""
              ref="upload"
              :auto-upload="false"
              :show-file-list="false"
              :multiple="false"
              :on-change="excelFileChanage"
            >
              <el-button
                ><i class="el-icon-upload el-icon--left"></i
                >上传（excel）文件</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div v-if="allowInput" slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="submit"
          :disabled="isDisable"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { icbcChannel } from '@/request/api'
export default {
  props: {
    dialogFormVisible: { type: Boolean, default: false },
    form: {
      type: Object,
      default: () => ({
        appName: '',
        bankOrganization: '',
        ecis: '',
        publicKey: '',
        privateKeyAddress: '',
        tableAddress: '',
        id: '',
      }),
    },
    allowInput: { type: Boolean, default: false },
  },
  data() {
    return {
      isDisable: true,
      formLabelWidth: '120px',
      childForm: this.form,
      fullscreenLoading: false,
      privateKeyFile: '',
      publicKeyFile: '',
      applicationExcelFile: '',
    }
  },
  watch: {
    form() {
      this.childForm = { ...this.form }
    },
    childForm: {
      handler(val) {
        let testForm = val
        this.isDisable =
          testForm.bankOrganization &&
          testForm.ecis &&
          testForm.publicKey &&
          testForm.privateKeyAddress &&
          testForm.tableAddress
            ? false
            : true
      },
      deep: true,
    },
  },
  methods: {
    publicFileChanage(file) {
      this.childForm.publicKey = file.name
      this.publicKeyFile = file.raw
    },
    privateFileChanage(file) {
      this.childForm.privateKeyAddress = file.name
      this.privateKeyFile = file.raw
    },
    excelFileChanage(file) {
      this.childForm.tableAddress = file.name
      this.applicationExcelFile = file.raw
    },
    async icbcChannelSubmit(formData) {
      try {
        this.fullscreenLoading = true
        const res = await icbcChannel(formData)
        this.fullscreenLoading = false
        if (res.status === 200) {
          this.$refs.childForm.resetFields()
          this.$emit('update-table')
          this.$emit('update:dialogFormVisible', false)
        } else {
          this.$message.error(res.message)
          this.$refs.childForm.resetFields()
        }
      } catch (error) {
        this.fullscreenLoading = false
        this.$emit('update:dialogFormVisible', false)
      }
    },
    submit() {
      let formData = new FormData()
      formData.append('privateKeyFile', this.privateKeyFile)
      formData.append('publicKeyFile', this.publicKeyFile)
      formData.append('applicationExcelFile', this.applicationExcelFile)
      formData.append('bankCardNo', this.childForm.ecis)
      formData.append('bankName', this.childForm.bankOrganization)
      formData.append('id', this.childForm.id)
      this.icbcChannelSubmit(formData)
    },
    close() {
      this.$refs.childForm.resetFields()
      this.$emit('update:dialogFormVisible', false)
    },
  },
}
</script>

<style lang="stylus" scoped>
.upload_box {
    display: flex;
}
</style>
