import { render, staticRenderFns } from "./platformSetDialog.vue?vue&type=template&id=271e9372&scoped=true&"
import script from "./platformSetDialog.vue?vue&type=script&lang=js&"
export * from "./platformSetDialog.vue?vue&type=script&lang=js&"
import style0 from "./platformSetDialog.vue?vue&type=style&index=0&id=271e9372&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271e9372",
  null
  
)

export default component.exports